import { useCallback } from 'react';

import { useRouter } from 'next/router';

import { useUser } from 'hooks/useUser';

import { IComponentFormLoginProps, IFormLoginProps } from './types';
import View from './View';

const FormLogin: React.FC<IComponentFormLoginProps> = ({ ignoreRedirect = false }) => {
  const { signIn } = useUser();
  const router = useRouter();

  const handleSubmitClick = useCallback(
    (values: IFormLoginProps) => {
      const redirectURL = router.query.redirect ? String(router.query.redirect) : undefined;

      return signIn({
        redirectURL,
        ignoreRedirect,
        login: values.login || '',
        password: values.password || '',
      });
    },
    [router.query.redirect, signIn, ignoreRedirect],
  );

  return <View handleSubmitClick={handleSubmitClick} ignoreRedirect={ignoreRedirect} />;
};

export default FormLogin;
