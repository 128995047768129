import { Wrapper } from 'components/atom/Grid';
import Logo from 'components/atom/Logo';
import FormLogin from 'components/molecule/FormLogin';

import { Container, Form } from './styles';

const Login: React.FC = () => (
  <Container>
    <Wrapper>
      <Logo version="arraial" />

      <Form>
        <h2>Acesse com suas credenciais:</h2>

        <FormLogin />
      </Form>
    </Wrapper>
  </Container>
);

export default Login;
