import styled from 'styled-components';

export const Container = styled.form`
  max-width: 25rem;
  margin: 0 auto;
`;

export const LoaderContainer = styled.div``;

export const RecoverPasswordContainer = styled.div`
  text-align: center;

  p {
    color: ${({ theme }) => theme.colors.gray};
  }

  a {
    color: ${({ theme }) => theme.colors.orange};
  }
`;
