import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  min-height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 1rem;
`;

export const Form = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  max-width: 100%;
  padding: 2rem 1.5rem 2rem 1.5rem;
  margin-top: 2rem;

  border-radius: 20px;

  .logo-site {
    margin: 0 auto 1rem auto;
  }

  p {
    font-size: 0.75rem;
  }

  p small,
  h2 {
    text-align: center;
  }

  h2 {
    color: ${({ theme }) => theme.colors.blue};

    @media (max-width: 991px) {
      margin: 0 0 0.5rem 0;
    }
  }

  hr {
    margin: 1.5rem 0;
    opacity: 0.2;

    @media (max-width: 991px) {
      margin: 1rem 0;
    }
  }

  .button {
    margin: 1rem auto;
    display: flex;
  }
`;
