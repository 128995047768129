import { useForm, Controller } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/atom/Form/Button';
import Input from 'components/atom/Form/Input';
import Loader from 'components/atom/Loader';

import schema from './schema';
import { Container, LoaderContainer } from './styles';
import { IFormLoginViewProps, IFormLoginProps } from './types';

const FormLoginView: React.FC<IFormLoginViewProps> = ({ handleSubmitClick }) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<IFormLoginProps>({
    resolver: yupResolver(schema()),
    defaultValues: {
      login: '',
      password: '',
      keepConected: false,
    },
  });

  return (
    <>
      <Container onSubmit={handleSubmit((data) => handleSubmitClick(data))}>
        <Controller
          name="login"
          control={control}
          rules={{ required: true }}
          render={({ field }) => <Input {...field} label="Usuário" error={errors.login?.message} />}
        />

        <Controller
          name="password"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Input {...field} type="password" label="Senha" error={errors.password?.message} />
          )}
        />

        {isSubmitting ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          <Button type="submit" theme="blue-light" text="Entrar" />
        )}
      </Container>
    </>
  );
};

export default FormLoginView;
