import { useUser } from 'hooks/useUser';

import Button from 'components/atom/Form/Button';

import { Container } from './styles';

interface IPageTemplateProps {
  children: React.ReactNode;
  disableFooter?: boolean;
}

const PageTemplate: React.FC<IPageTemplateProps> = ({ disableFooter = false, children }) => {
  const { name, signOut } = useUser();

  return (
    <Container>
      <section>{children}</section>

      {!disableFooter && (
        <footer>
          <p>{name} |</p>

          <Button theme="white-orange" type="button" text="Sair" onClick={() => signOut()} />
        </footer>
      )}
    </Container>
  );
};

export default PageTemplate;
