import * as yup from 'yup';

const schema = (): yup.AnyObjectSchema => {
  const defaultErrorMessage = 'Campo obrigatório!';

  return yup.object({
    login: yup.string().required(defaultErrorMessage),
    password: yup.string().required(defaultErrorMessage),
  });
};

export default schema;
