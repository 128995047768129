import { GetServerSideProps } from 'next';
import { NextSeo } from 'next-seo';

import Template from 'templates/Default';

import PageLogin from 'pages/Auth/Login';
import routeMiddleware from 'security/route-middleware';

import seo from 'config/seo';

const MyPageLogin: React.FC = () => (
  <>
    <NextSeo title={`${seo().title} - Login`} />

    <Template disableFooter>
      <PageLogin />
    </Template>
  </>
);

export default MyPageLogin;

export const getServerSideProps: GetServerSideProps = async (context) => {
  const routeData = await routeMiddleware({ context });
  if (routeData) return routeData;

  return {
    props: {},
  };
};
