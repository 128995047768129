import styled from 'styled-components';

export const Container = styled.article`
  flex: 1;
  min-height: 100vh;

  display: flex;
  flex-direction: column;

  & > section {
    flex: 1;

    max-width: 800px;
    margin: 0 auto;
  }

  & > footer {
    padding: 1rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;

    button {
      font-size: 1rem;
      border-width: 0;
      padding: 1rem 2rem;
    }
  }
`;
